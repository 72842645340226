import React, { useState } from 'react';
import styled from '@emotion/styled';
import AppLogoImg from '../../assets/images/logo_btn.svg';
import AppQrImg from '../../assets/images/img_download.svg';
import { ZIndex } from '../../constants/zIndex';
import { RED } from '../../constants/colors';
import { pxToRem } from '../../utils/common';
import { getDesktopStyle, showOnDesktopStyle } from '../../styles/layout';
import { css } from '@emotion/react';

const AppPopup = () => {
	const [isVisible, setIsVisible] = useState(true);

	const handleClose = () => {
		setIsVisible(false);
	};
	return (
		<>
			{isVisible && ( // isVisible이 true일 때만 표시
				<AppPopupWrapper>
					<AppPopupInner>
						<Title>펫닥 앱 운영 중지 안내</Title>
						<Desc>펫닥 앱 업그레이드 작업을 위해 펫닥 앱의 운영을<br />
							잠시 중지하게 되었습니다.<br />
							기간 동안 기존 상담내역, 스캐너 이용 등 앱 사용이 불가 합니다.<br />
							보다 나은 콘텐츠와 기능으로 빠르게 찾아 뵐 수 있도록<br />
							최선을 다하고 있습니다.<br />
							다시 한번 앱 이용에 불편을 드려 죄송합니다.</Desc>
						<MobileDesc>펫닥 앱 업그레이드 작업을 위해 펫닥 앱의 운영을<br />
							잠시 중지하게 되었습니다.<br />
							기간 동안 기존 상담내역, 스캐너 이용 등<br /> 앱 사용이 불가 합니다.<br />
							보다 나은 콘텐츠와 기능으로 빠르게 찾아 뵐 수<br />있도록
							최선을 다하고 있습니다.<br />
							다시 한번 앱 이용에 불편을 드려 죄송합니다.</MobileDesc>
						<Buttons onClick={handleClose}>확인</Buttons>
					</AppPopupInner>
				</AppPopupWrapper>
			)}
		</>
	);
};

export default AppPopup;

const AppPopupWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0,0,0, 0.4);
	z-index: 9999;
	${getDesktopStyle(css`
	
	`)}
`;
const AppPopupInner = styled.div`
		width: calc(100% - ${pxToRem(60)});
		position: absolute;
		height: auto;
		left: ${pxToRem(30)};
		top: 50%;
		transform: translateY(-50%);
		background: #ffffff;
		padding: ${pxToRem(30)} ${pxToRem(37)} ${pxToRem(65)} ${pxToRem(32)};
	${getDesktopStyle(css`
		width: ${pxToRem(417)};
		height: ${pxToRem(272)};		
		padding: ${pxToRem(40)} ${pxToRem(32)} 0 ${pxToRem(32)};
		left: 50%;
		top: 50%;
		color:#2A303C;	
		transform: translate(-50%, -50%);	
		z-index: 9999;
		text-align:center;
	`)}
`;
const Title = styled.h2`
	font-weight: 700;
	font-size: ${pxToRem(16)};
	margin-bottom: ${pxToRem(10)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(18)};
		margin-bottom: ${pxToRem(10)};
	`)}
`;
const Desc = styled.p`
	display:none;
	font-weight: 400;
	font-size: ${pxToRem(12)};
	margin-bottom: ${pxToRem(10)};
	color:#5C6272;
	line-height: ${pxToRem(20)};
	letter-spacing: -${pxToRem(0.5)};
	word-break: keep-all; 

	${getDesktopStyle(css`
		display:block;
		font-size: ${pxToRem(14)};
		margin-bottom: ${pxToRem(10)};
	`)}
`;
const MobileDesc = styled.p`
	
	font-weight: 400;
	font-size: ${pxToRem(12)};
	margin-bottom: ${pxToRem(10)};
	color:#5C6272;
	line-height: ${pxToRem(20)};
	letter-spacing: -${pxToRem(0.5)};
	word-break: keep-all; 

	${getDesktopStyle(css`
		display:none;
		font-size: ${pxToRem(14)};
		margin-bottom: ${pxToRem(10)};
	`)}
`;
const Buttons = styled.button`
	font-weight: 700;
	width: 100%;
	font-size: ${pxToRem(16)}; 
	height: ${pxToRem(55)};
	line-height: ${pxToRem(47)};
	color:#fff;
	background: #FF5A5C;
	text-align:center;
	position:absolute;
	left:0;
	bottom:0;
	font-size: ${pxToRem(15)}; 

	${getDesktopStyle(css`
		font-size: ${pxToRem(14)}; 
		height: ${pxToRem(47)};
	`)}
`;